<template>
  <div id="askpanda_chat_widget">
    <DCForm v-if="formButton && webId == 'OTJxZGdSYzN6MjdpSEg4azRYMVJpdjV4bzdVWFVHK0c'"
            :web-id="webId"
            :inline-form-text="formButtonText"
            :colors="colors"
            :title-image-url="titleImageUrl"
            :is-open="isFormOpen"
            :close="closeForm"
            :open="openForm"
    />
    <NBBForm v-else-if="formButton && webId == 'UkxmUzJzNHYzOGF5QkszMmVhU1NmdEVZcmxTUWdqK0U'"
            :web-id="webId"
            :inline-form-text="formButtonText"
            :colors="colors"
            :title-image-url="titleImageUrl"
            :is-open="isFormOpen"
            :close="closeForm"
            :open="openForm"
    />
    <div v-else>
      <Header
          :chosenColor="chosenColor"
          :colors="colors"
      />
      <beautiful-chat
          :hide-user-input="hideUserInput"
          :inline-launcher="inlineButton"
          :inline-launcher-text="inlineButtonText"
          :float-launcher="floatButton"
          :float-launcher-text="floatButtonText"
          :always-scroll-to-bottom="alwaysScrollToBottom"
          :close="closeChat"
          :colors="colors"
          :is-open="isChatOpen"
          :message-list="messageList"
          :message-styling="messageStyling"
          :new-messages-count="newMessagesCount"
          :on-message-was-sent="onMessageWasSent"
          :open="openChat"
          :participants="participants"
          :show-close-button="true"
          :show-launcher="showLauncher"
          :show-emoji="false"
          :show-file="false"
          :show-typing-indicator="showTypingIndicator"
          :show-edition="false"
          :show-deletion="false"
          :show-confirmation-deletion="true"
          :confirmation-deletion-message="'Are you sure? (you can customize this message)'"
          :title-image-url="titleImageUrl"
          :disable-user-list-toggle="false"
          @onType="handleOnType"
      >
        <template v-slot:text-message-body="scopedProps">
          <div v-html="scopedProps.messageText" class="sc-message--text-content"/>
          <p v-if="scopedProps.message.data.meta" :style="{color: scopedProps.messageColors.color}"
             class="sc-message--meta">
            {{ scopedProps.message.data.meta }}
          </p>
        </template>
        <template v-slot:system-message-body="{ message }">
          [System]: {{ message.text }}
        </template>
      </beautiful-chat>
    </div>
  </div>
</template>

<script>
import {HTTP} from './http-common'
import DCForm from './DCForm.vue'
import NBBForm from "./NBBForm.vue"

export default {
  name: 'app',
  components: {NBBForm, DCForm},
  props: {
    secretKey: {
      type: String,
      default: () => ''
    },
    webId: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      participants: [],
      titleImageUrl: process.env.VUE_APP_BASE_URL + '/ask_panda_logo.png',
      messageList: [],
      newMessagesCount: 0,
      isFormOpen: false,
      showLauncher: false,
      isChatOpen: false,
      showTypingIndicator: '',
      floatButton: true,
      floatButtonText: undefined,
      inlineButton: false,
      inlineButtonText: undefined,
      formButton: false,
      formButtonText: undefined,
      colors: null,
      availableColors: {
        blue: {
          header: {
            bg: '#409EFF',
            text: '#ffffff'
          },
          launcher: {
            bg: '#409EFF'
          },
          messageList: {
            bg: '#ffffff'
          },
          sentMessage: {
            bg: '#409EFF',
            text: '#ffffff'
          },
          receivedMessage: {
            bg: '#f4f7f9',
            text: '#222222'
          },
          userInput: {
            bg: '#f4f7f9',
            text: '#565867'
          },
          userList: {
            bg: '#fff',
            text: '#212121'
          }
        }
      },
      chosenColor: null,
      alwaysScrollToBottom: true,
      messageStyling: true,
      userIsTyping: false,
      // handling talk
      talkId: '',
      context: '',
      followupIntent: '',
      followupEvent: '',
      hideUserInput: true
    }
  },
  computed: {},
  created() {
    this.setColor('blue')
  },
  mounted() {
    if (!this.webId) {
      return
    }
    HTTP.defaults.headers.common['Panda-Authorization'] = this.webId
    // load settings for company
    HTTP.get(`/talks/settings/${this.webId}`)
        .then((res) => {
          // check if integration enabled
          if (res.data) {
            // form enabled
            if (res.data.formButton) {
              this.showLauncher = false
              this.formButton = res.data.formButton
              if (res.data.formButtonText) {
                this.formButtonText = res.data.formButtonText
              }
              this.colors = res.data.color
              if (res.data.titleImage) {
                this.titleImageUrl = res.data.titleImage
              }

              // chat widget
            } else {
              if (res.data.titleImage) {
                this.titleImageUrl = res.data.titleImage
              }
              this.colors = res.data.color
              this.participants.push(res.data.agent)
              this.showLauncher = true
              this.floatButton = res.data.floatButton
              this.inlineButton = res.data.inlineButton
              if (res.data.floatButtonText) {
                this.floatButtonText = res.data.floatButtonText
              }
              if (res.data.inlineButtonText) {
                this.inlineButtonText = res.data.inlineButtonText
              }
            }
          }
        }).catch(() => {
      this.sendSystemMsg('Sorry, there was a problem processing your request, please try again later')
      this.setColor('blue')
    })
  },
  methods: {
    sendSystemMsg(text) {
      if (text.length > 0) {
        this.newMessagesCount = this.isChatOpen
            ? this.newMessagesCount
            : this.newMessagesCount + 1
        const message = {
          type: 'system',
          id: Math.random(),
          data: {text}
        }
        this.messageList.push(message)
        this.showTypingIndicator = ''
      }
      // we cannot recover from the error, just close the chat
      this.restartChat(true)
      // this.showLauncher = false
    },
    openChat() {
      if (this.formButton) {
        this.openForm()
      } else {
        this.isChatOpen = true
        this.newMessagesCount = 0
        // new conversation
        if (this.messageList.length === 0 && this.talkId === '') {
          this.loadStart()
        }
      }

    },
    closeChat() {
      this.isChatOpen = false
    },
    openForm() {
      this.isFormOpen = true
      // RESET FORM?
    },
    closeForm() {
      this.isFormOpen = false
    },
    setColor(color) {
      this.colors = this.availableColors[color]
      this.chosenColor = color
    },
    handleOnType() {
      this.$root.$emit('onType')
      this.userIsTyping = true
    },
    restartChat(close) {
      this.messageList = []
      this.talkId = ''
      this.context = ''
      this.followupIntent = ''
      this.followupEvent = ''
      if (close) {
        this.closeChat()
      } else {
        this.openChat()
      }
    },
    async loadStart() {
      if (!this.webId) {
        return
      }
      try {
        this.showTypingIndicator = 'askpanda'
        const message = (await HTTP.get(`/talks/start/${this.webId}`)).data
        this.messageList.push(message)

        this.talkId = message.talkId
        this.context = message.context
        this.followupIntent = message.followupIntent
        this.followupEvent = message.followupEvent

        // do we have any follow up intents, trigger by event
        if (this.followupEvent) {
          this.loadFollowupEvent(this.followupEvent)
        }
      } catch (e) {
        this.sendSystemMsg('Sorry, there was a problem processing your request, please try again later')
      }
    },
    onMessageWasSent(message) {
      // post response save, update id, get next message(question) based on event
      this.messageList.push(message)
      this.showTypingIndicator = 'askpanda'
      message.context = this.context
      HTTP.post(`/talks/${this.talkId}`, message)
          .then((res) => {
            // this.messageList.push(res.data)
            message.id = res.data.id
            // for date value, parse and reformat in correct format
            if (res.data.data) {
              message.data.text = res.data.data.text
            }
            // this.followupIntent = res.data.followupIntent
            this.followupEvent = res.data.followupEvent
            this.showTypingIndicator = ''

            // response message recorded, check for next intent or
            // re prompt user if we have validation error
            if (res.data.prompt) {
              this.showTypingIndicator = 'askpanda'
              setTimeout(() => {
                this.messageList.push(res.data.prompt)
                // on error validation re prompt user to enter valid value
                // should use the same context
                if (res.data.prompt.context) {
                  this.context = res.data.prompt.context
                }
                this.showTypingIndicator = ''
              }, 500)

              // follow up intent returned, add it to message list
              // and wait for user input
            } else if (res.data.followupIntent) {
              this.showTypingIndicator = 'askpanda'

              // Handle show input
              if (res.data.followupIntent.suggestions) {
                this.hideUserInput = true
              } else {
                this.hideUserInput = false
              }

              setTimeout(() => {
                this.messageList.push(res.data.followupIntent)
                this.context = res.data.followupIntent.context
                this.showTypingIndicator = ''
              }, 500)

              // do we have any follow up events
              // in case we don't require user input
            } else if (this.followupEvent) {
              // hide user input on follow up event
              this.hideUserInput = true
              this.loadFollowupEvent(this.followupEvent)
            }
          }).catch(() => {
        this.sendSystemMsg('Sorry, there was a problem processing your request, please try again later')
      })
    },
    loadFollowupEvent(followupEvent) {
      this.showTypingIndicator = 'askpanda'

      // set slight delay
      setTimeout(() => {
        // we ended chat, clear messages and close chat or start new quote
        if (followupEvent === 'CLOSE_CHAT') {
          this.restartChat(true)
          this.closeChat()
          return
        } else if (followupEvent === 'NEW_QUOTE') {
          this.restartChat(false)
          return
        }

        try {
          HTTP
              .get(`/talks/${this.talkId}/events/${followupEvent}`)
              .then((res) => {
                this.messageList.push(res.data)
                this.context = res.data.context
                this.followupIntent = res.data.followupIntent
                this.followupEvent = res.data.followupEvent

                this.showTypingIndicator = ''

                // Handle show input
                if (res.data.suggestions) {
                  this.hideUserInput = true
                } else {
                  this.hideUserInput = false
                }

                // events can be chained do we have any follow up events
                // in case we don't require user input, otherwise process as normal intent
                if (this.followupEvent) {
                  this.loadFollowupEvent(this.followupEvent)
                }
              }).catch(() => {
            this.sendSystemMsg('Sorry, there was a problem processing your request, please try again later')
          })
        } catch (e) {
          this.sendSystemMsg('Sorry, there was a problem processing your request, please try again later')
        }
      }, 500)
    },
  }
}
</script>

<style scoped>
#askpanda_chat_widget {
  position: relative;
  z-index: 9999;
  font-family: Avenir Next, Helvetica Neue, Helvetica, sans-serif;
}
</style>
