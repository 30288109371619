import axios from 'axios';

const username = process.env.VUE_APP_BASIC_AUTH_USERNAME
const password = process.env.VUE_APP_BASIC_AUTH_PASSWORD

const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')

export const HTTP = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
        Authorization: `Basic ${token}`
    }
})