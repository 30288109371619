<template>
    <div>
        <div>
            <a @click.prevent="isOpen ? close() : open()">{{ inlineFormText }}</a>
        </div>
        <div class="ap-form-window" :class="{opened: isOpen, closed: !isOpen}">
            <div class="ap-form-header" :style="{background: colors.header.bg, color: colors.header.text}">
                <img v-if="titleImageUrl" class="ap-header--img" :src="titleImageUrl" alt="Logo" />
                <div class="ap-header--title">{{ headerTxt }}</div>
                <div class="ap-header--close-button" @click="close()" :style="{background: colors.header.text}">
                    <img :src="icons.close.img" :alt="icons.close.name"/>
                </div>
            </div>

            <div class="ap-form-container">
                <div class="ap-row ap-centered" v-if="failed">
                    <div class="ap-form-message-body">
                        <p>{{ errorMessage }}</p>
                        <br>
                        <br>
                        <div class="ap-row">
                            <button type="button" class="ap-btn" @click="_clearAndClose">Close</button>
                            <button type="submit" class="ap-btn primary" :style="{background: colors.launcher.bg}" @click="_clearForm()" :disabled="submitting">
                                Start new Enquiry
                            </button>
                        </div>
                    </div>
                </div>
                <div class="ap-row ap-centered" v-if="quoteEid && !failed && generated">
                    <p>Your initial quote estimate for consultation with you is: </p>
                    <strong>Total: {{ totalAmount }}</strong>
                    <p><strong>Ref#: {{ quoteReference }}</strong></p>
                    <br>
                    <p>Please note the above is an initial quote estimate only, and may change.</p>
                    <p>If you are happy with the fee please provide the information below and click "Fee ok, contact me"
                        button, so that a member of our team may contact you to discuss this further. You do not need
                        to pay anything now.</p>
                    <p>If you want to think about it, click "Maybe, email me quote" button and we will email you quote
                        estimate to you.</p>
                    <br>
                    <form>
                        <div class="ap-row">
                            <div class="ap-input-group">
                                <input type="text" v-model="apAcceptForm.fullName" maxlength="255"
                                       placeholder="Your Full Name*"/>
                            </div>
                        </div>
                        <div class="ap-row">
                            <div class="ap-col-half">
                                <div class="ap-input-group">
                                    <input type="email" v-model="apAcceptForm.email" maxlength="155"
                                           placeholder="Your email address*"/>
                                </div>
                            </div>
                            <div class="ap-col-half">
                                <div class="ap-input-group">
                                    <input type="text" v-model="apAcceptForm.phone" maxlength="20"
                                           placeholder="Phone number*"/>
                                </div>
                            </div>
                        </div>
                        <div class="ap-row">
                            <div class="ap-input-group">
                                <input id="ap_optIn" type="checkbox" v-model="apAcceptForm.optedIn"/>
                                <label for="ap_optIn" class="ap-label">I would you like to join your mailing list</label>
                            </div>
                        </div>
                        <div class="ap-row">
                            <div class="ap-error">{{ error }}</div>
                            <div class="ap-input-group">
                                <button type="button" class="ap-btn" @click="_email" :disabled="submittingEmail">
                                    {{ submittingEmail ? 'Submitting ...' : 'Maybe, email me quote' }}
                                </button>
                                <button type="submit" class="ap-btn primary" :style="{background: colors.launcher.bg}" @click="_accept" :disabled="submitting">
                                    {{ submitting ? 'Submitting ...' : 'Fee ok, contact me' }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="ap-row ap-centered" v-if="quoteEid && !failed && accepted">
                    <div class="ap-form-message-body">
                        <p>Thank you for accepting the initial quote estimate. A member of our team will be in touch
                            with you.</p>
                        <p>We also sent you an email with a summary of your enquiry.</p>
                        <br>
                        <div class="ap-row">
                            <button type="button" class="ap-btn" @click="_clearAndClose">Close</button>
                            <button type="submit" class="ap-btn primary" :style="{background: colors.launcher.bg}" @click="_clearForm()" :disabled="submitting">
                                Start new Enquiry
                            </button>
                        </div>
                    </div>
                </div>
                <div class="ap-row ap-centered" v-if="quoteEid && !failed && emailed">
                    <div class="ap-form-message-body">
                        <p>The email has been sent. Please check that you have received the email. If you have not
                            received it, please check your junk mail folder.</p>
                        <p> Also, please check the summary of your matter in the email to ensure
                            that it is accurate. If not, please complete a new enquiry.</p>
                        <p>Thank you for your time.</p>
                        <br>
                        <div class="ap-row">
                            <button type="button" class="ap-btn" @click="_clearAndClose">Close</button>
                            <button type="submit" class="ap-btn primary" :style="{background: colors.launcher.bg}" @click="_clearForm()" :disabled="submitting">
                                Start new Enquiry
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="!quoteEid && !failed">
                    <form ref="appNbbForm">
                        <p>Please fill in the form and we'll get back to you as soon as we can.</p>
                        <div class="ap-row">
                            <div class="ap-label">Nature of your Education enquiry</div>
                            <div class="ap-col-half">
                                <select v-model="appNbbForm.sector" @change="_cleanupOnChange()">
                                    <option value="">Please select sector*</option>
                                    <option value="Further & Higher Education">Further & Higher Education</option>
                                    <option value="Schools & Children">Schools & Children</option>
                                </select>
                            </div>
                            <div class="ap-col-half">
                                <select v-model="appNbbForm.issue">
                                    <option value="">What's the issue?*</option>
                                    <option v-for="item in issues"
                                            :key="item"
                                            :label="item"
                                            :value="item"/>
                                </select>
                            </div>
                        </div>
                        <div class="ap-row">
                            <div v-if="appNbbForm.sector ==='Schools & Children'">
                                <div class="ap-col-half">
                                    <select v-model="appNbbForm.subSector">
                                        <option value="">What's school type?*</option>
                                        <option value="State">State school</option>
                                        <option value="Private">Private school</option>
                                    </select>
                                </div>
                                <div class="ap-col-half">
                                    <div class="ap-input-group">
                                        <input id="ap_legalAid" type="checkbox" v-model="appNbbForm.sen"/>
                                        <label for="ap_legalAid" class="ap-label">Does your child have SEN?</label>
                                    </div>
                                </div>
                            </div>
                            <div class="ap-input-group" v-if="appNbbForm.sector === 'Further & Higher Education'">
                                <input type="text" v-model="appNbbForm.institutionName" maxlength="150"
                                       placeholder="Institution name"/>
                            </div>
                            <div class="ap-input-group" v-if="appNbbForm.sector ==='Schools & Children'">
                                <input type="text" v-model="appNbbForm.schoolName" maxlength="150"
                                       placeholder="What the school name?"/>
                            </div>
                        </div>
                        <div class="ap-row">
                            <div class="ap-col-half">
                                <div class="ap-input-group">
                                    <input type="text" v-model="appNbbForm.country" maxlength="100"
                                           placeholder="What country are you in?"/>
                                </div>
                            </div>
                            <div class="ap-col-half">
                                <div class="ap-input-group">
                                    <input type="text" v-model="appNbbForm.documentTypes" maxlength="255"
                                           placeholder="What documents do you have?"/>
                                </div>
                            </div>
                        </div>
                        <div class="ap-row">
                            <div class="ap-label">Are there any deadlines in your case?</div>
                            <div class="ap-col-half">
                                <div class="ap-input-group">
                                    <div class="ap-col-third">
                                        <input type="number" v-model="appNbbForm.deadlineDateDay" placeholder="DD" min="1"
                                               max="31"/>
                                    </div>
                                    <div class="ap-col-third">
                                        <input type="number" v-model="appNbbForm.deadlineDateMonth" placeholder="MM"
                                               min="1"
                                               max="12"/>
                                    </div>
                                    <div class="ap-col-third">
                                        <input type="number" v-model="appNbbForm.deadlineDateYear" placeholder="YYYY"/>
                                    </div>
                                </div>
                            </div>
                            <div class="ap-col-half"
                                 v-if="appNbbForm.deadlineDateDay || appNbbForm.deadlineDateMonth || appNbbForm.deadlineDateYear ">
                                <div class="ap-input-group">
                                    <input type="text" placeholder="What's the deadline for?" maxlength="255"/>
                                </div>
                            </div>
                        </div>
                        <div class="ap-row">
                            <div class="ap-col-half">
                                <div class="ap-input-group">
                                    <select v-model="appNbbForm.meetingVia">
                                        <option value="">Type of Legal Advice?*</option>
                                        <option v-for="item in meetingOptions"
                                                :key="item"
                                                :label="item"
                                                :value="item"/>
                                    </select>
                                </div>
                            </div>
                            <div class="ap-col-half">
                                <div class="ap-input-group">
                                    <select v-model="appNbbForm.heardAboutUs">
                                        <option value="">How did you hear about us?*</option>
                                        <option v-for="item in heardOptions"
                                                :key="item"
                                                :label="item"
                                                :value="item"/>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="ap-row">
                            <div class="ap-input-group">
                                <input id="ap_legalAid" type="checkbox" v-model="appNbbForm.legalAid"/>
                                <label for="ap_legalAid" class="ap-label">Are you looking for Legal Aid?</label>
                            </div>
                        </div>
                        <div class="ap-row">
                            <div class="ap-input-group">
                                <input id="ap_existingClient" type="checkbox" v-model="appNbbForm.existingClient"/>
                                <label for="ap_existingClient" class="ap-label">Are you an existing client?</label>
                            </div>
                        </div>
                        <div class="ap-row">
                            <div class="ap-input-group">
                                <input id="ap_alreadyAdvised" type="checkbox" v-model="appNbbForm.alreadyAdvised"/>
                                <label for="ap_alreadyAdvised" class="ap-label">Have you taken legal advice from a union or another law firm on your issue already?
                                </label>
                            </div>
                        </div>
                        <div class="ap-row" v-if="appNbbForm.alreadyAdvised">
                            <div class="ap-input-group">
                                <input type="text" placeholder="Who with?" v-model="appNbbForm.alreadyAdvisedWith"
                                       maxlength="255"/>
                            </div>
                        </div>
                        <div class="ap-row">
                            <div class="ap-input-group">
                                <textarea v-model="appNbbForm.additionalInfo" rows="3"
                                          placeholder="Additional information about your enquiry"
                                          maxlength="1000"/>
                            </div>
                        </div>
                        <div class="ap-row ap-centered">
                            <div class="ap-error" v-if="error">{{ error }}</div>
                            <button type="button" class="ap-btn" @click="_clearAndClose">Cancel</button>
                            <button type="submit" class="ap-btn primary" :style="{background: colors.launcher.bg}"  @click="_submitData" :disabled="submitting">
                                {{ submitting ? 'Submitting ...' : 'Submit Enquiry' }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CloseIcon from './assets/close_icon.png'
import {HTTP} from './http-common'

export default {
    name: "NBBForm",
    components: {},
    props: {
        icons: {
            type: Object,
            default: function () {
                return {
                    close: {
                        img: CloseIcon,
                        name: 'default'
                    }
                }
            }
        },
        webId: {
            type: String,
            default: () => ''
        },
        inlineFormText: {
            type: String,
            default: () => 'Start Now'
        },
        titleImageUrl: {
          type: String,
          default: () => ''
        },
        colors: {
          type: Object,
          required: true
        },
        isOpen: {
            type: Boolean,
            default: () => false
        },
        open: {
            type: Function,
            required: true
        },
        close: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            headerTxt: 'How can we help you?',
            error: '',
            errorMessage: '',
            submitting: false,
            submittingEmail: false,
            failed: false,
            generated: false,
            emailed: false,
            accepted: false,
            quoteEid: '',
            totalAmount: '',
            quoteReference: '',
            issues: [],
            heardOptions: ['Recommended by a friend', 'Recommended by a lawyer', 'Repeat client', 'Search engine', 'Media', 'Other'],
            meetingOptions: ['Face to face meeting', 'Phone call', 'Video call'],
            // sub sector
            educationFHSubSector: ['Home student', 'International student'],
            educationSCSubSector: ['State school', 'Private school'],
            // issue
            educationFHIssues: ['Academic Appeals', 'Admissions', 'Breach of Contract', 'CAS issue', 'Complaints',
                'Degree Classification', 'Disciplinary / Misconduct Issues', 'Discrimination Issue', 'Employment',
                'Fitness to Practice proceedings', 'Negligence', 'OIA', 'Plagiarism', 'Problems with PhD',
                'University Fees', 'Withdrawal from University', 'Other'],
            educationSCIssues: ['Admission to school', 'Breach of Contract', 'Bullying', 'Employment', 'Exclusions from school',
                'Disability Discrimination', 'Race Discrimination', 'Negligence', 'Complaints', 'Other'],

            appNbbForm: {
                country: '',
                docPages: 0,
                documentTypes: '',
                deadlineDateDay: '',
                deadlineDateMonth: '',
                deadlineDateYear: '',
                deadlineFor: '',
                meetingVia: '',
                heardAboutUs: '',
                additionalInfo: '',
                legalAid: false,
                alreadyAdvised: false,
                alreadyAdvisedWith: '',
                existingClient: false,
                specialty: 'Education',
                sector: '',
                subSector: '',
                issue: '',
                sen: null,
                institutionName: '',
                schoolName: ''
            },
            apAcceptForm: {
                fullName: '',
                email: '',
                phone: '',
                optedIn: false
            }
        }
    },
    methods: {
        _clearAndClose() {
            this._clearForm()
            this.close()
        },
        _clearForm() {
            this.headerTxt = 'How can we help you?'
            this.error = ''
            this.errorMessage = ''
            this.quoteEid = ''
            this.failed = false
            this.generated = false
            this.emailed = false
            this.accepted = false
            this.totalAmount = ''
            this.quoteReference = ''
            this.issues = []
            this.appNbbForm.existingClient = false
            this.appNbbForm.legalAid = false
            this.appNbbForm.country = ''
            this.appNbbForm.deadlineDateDay = ''
            this.appNbbForm.deadlineDateMonth = ''
            this.appNbbForm.deadlineDateYear = ''
            this.appNbbForm.deadlineDateFor = ''
            this.appNbbForm.documentTypes = ''
            this.appNbbForm.meetingVia = ''
            this.appNbbForm.contactBy = ''
            this.appNbbForm.heardAboutUs = ''
            this.appNbbForm.additionalInfo = ''
            this.appNbbForm.specialty = 'Education'
            this.appNbbForm.sector = ''
            this.appNbbForm.subSector = ''
            this.appNbbForm.issue = ''
            this.appNbbForm.sen = ''
            this.appNbbForm.institutionName = ''
            this.appNbbForm.schoolName = ''
            this.appNbbForm.alreadyAdvised = ''
            this.appNbbForm.alreadyAdvisedWith = ''
            this.apAcceptForm.fullName = ''
            this.apAcceptForm.email = ''
            this.apAcceptForm.phone = ''
            this.apAcceptForm.optedIn = false
        },
        _cleanupOnChange() {
            this.appNbbForm.subSector = ''
            this.appNbbForm.issue = ''
            this.appNbbForm.sen = ''
            this.appNbbForm.institutionName = ''
            this.appNbbForm.schoolName = ''
            this.error = ''
            if (this.appNbbForm.sector === 'Schools & Children') {
                this.issues = this.educationSCIssues
            } else if (this.appNbbForm.sector === 'Further & Higher Education') {
                this.issues = this.educationFHIssues
            } else {
                this.issues = []
            }
        },
        _submitData(e) {
            this.error = ''
            if (!this.webId) {
                this.error = "Sorry there was a problem submitting form. Please refresh the page and try again."
                e.preventDefault();
                return
            }

            let errorMsg = this._validateAcceptForm()
            if (errorMsg) {
                this.error = errorMsg
                e.preventDefault();
                return
            }

            let deadlineDate = ''
            if (this.appNbbForm.deadlineDateYear || this.appNbbForm.deadlineDateMonth || this.appNbbForm.deadlineDateDay) {
                if (!this._validDate()) {
                    this.error = "Invalid Deadline date format"
                    e.preventDefault();
                    return false
                }
                deadlineDate = new Date(this.appNbbForm.deadlineDateYear, this.appNbbForm.deadlineDateMonth - 1, this.appNbbForm.deadlineDateDay)
                const now = new Date()
                if (deadlineDate < now) {
                    this.error = "Deadline date cannot be in the past"
                    e.preventDefault();
                    return false
                }
            }

            this.submitting = true
            setTimeout(() => {
                HTTP.post(`/talks/forms/${this.webId}`,
                    {
                        legalAid: this.appNbbForm.legalAid,
                        alreadyAdvised: this.appNbbForm.alreadyAdvised,
                        alreadyAdvisedWith: this.appNbbForm.alreadyAdvisedWith,
                        existingClient: this.appNbbForm.existingClient,
                        country: this.appNbbForm.country,
                        deadlineDate: deadlineDate,
                        deadlineFor: this.appNbbForm.deadlineFor,
                        documentTypes: this.appNbbForm.documentTypes,
                        meetingVia: this.appNbbForm.meetingVia,
                        heardAboutUs: this.appNbbForm.heardAboutUs,
                        additionalInfo: this.appNbbForm.additionalInfo,
                        specialty: this.appNbbForm.specialty,
                        sector: this.appNbbForm.sector,
                        subSector: this.appNbbForm.subSector,
                        issue: this.appNbbForm.issue,
                        sen: this.appNbbForm.sen,
                        institutionName: this.appNbbForm.institutionName,
                        schoolName: this.appNbbForm.schoolName,
                    })
                    .then((res) => {
                        this.submitting = false
                        if (res.data.code === 200) {
                            this.quoteEid = res.data.result.quoteEid
                            this.totalAmount = res.data.result.totalAmount
                            this.quoteReference = res.data.result.quoteReference
                            this.generated = true
                            this.headerTxt = 'Thank you for your enquiry'
                        } else {
                            this.failed = true
                            this.headerTxt = 'Sorry we are not able to help!'
                            this.errorMessage = res.data.errorMessage
                        }
                    })
                    .catch(() => {
                        this.submitting = false
                        this.failed = true
                        this.headerTxt = 'Sorry something went wrong!'
                        this.errorMessage = "Sorry there was a problem submitting form. Please refresh the page and try again."
                    })
            }, 1000)
        },
        _accept(e) {
            if (!this.quoteEid || !this.generated) {
                this.error = "Sorry there was a problem submitting form. Please refresh the page and try again."
                e.preventDefault();
                return
            }

            let errorMsg = this._validateClientForm()
            if (errorMsg) {
                this.error = errorMsg
                e.preventDefault();
                return
            }

            this.submitting = true
            setTimeout(() => {
                HTTP.post(`/talks/forms/${this.quoteEid}/accept`,
                    {
                        clientName: this.apAcceptForm.fullName,
                        clientEmail: this.apAcceptForm.email,
                        clientPhone: this.apAcceptForm.phone,
                        optedIn: this.apAcceptForm.optedIn
                    }
                ).then(() => {
                    this.accepted = true
                    this.generated = false
                    this.submitting = false
                    this.headerTxt = 'Thank you!'
                }).catch(() => {
                    this.failed = true
                    this.submitting = false
                    this.headerTxt = 'Sorry something went wrong!'
                    this.errorMessage = "Sorry there was a problem submitting form. Please refresh the page and try again."
                })
            }, 2000)
        },
        _email(e) {
            if (!this.quoteEid || !this.generated) {
                this.error = "Sorry there was a problem submitting form. Please refresh the page and try again."
                e.preventDefault();
                return
            }
            let errorMsg = this._validateClientForm()
            if (errorMsg) {
                this.error = errorMsg
                e.preventDefault();
                return
            }

            this.submittingEmail = true

            setTimeout(() => {
                HTTP.post(`/talks/forms/${this.quoteEid}/email`,
                    {
                        clientName: this.apAcceptForm.fullName,
                        clientEmail: this.apAcceptForm.email,
                        clientPhone: this.apAcceptForm.phone,
                        optedIn: this.apAcceptForm.optedIn
                    }
                ).then(() => {
                    this.emailed = true
                    this.accepted = false
                    this.generated = false
                    this.submittingEmail = false
                    this.headerTxt = 'Thank you!'
                }).catch(() => {
                    this.failed = true
                    this.submittingEmail = false
                    this.headerTxt = 'Sorry something went wrong.'
                    this.errorMessage = "Sorry there was a problem submitting form. Please refresh the page and try again."
                })
            }, 2000)
        },
        _validDate() {
            if (this.appNbbForm.deadlineDateDay < 1 || this.appNbbForm.deadlineDateDay > 31) {
                return false
            }
            if (this.appNbbForm.deadlineDateMonth < 1 || this.appNbbForm.deadlineDateMonth > 12) {
                return false
            }
            return true
        },
        _validEmail: function (email) {
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        _validateClientForm() {
            if (!this.apAcceptForm.fullName) {
                return "Please enter your Full Name."
            }
            if (this.apAcceptForm.fullName.length > 150) {
                return "Institution name must be less then 150 char long."
            }
            if (!this.apAcceptForm.email || !this._validEmail(this.apAcceptForm.email) || this.apAcceptForm.email > 150) {
                return "Please enter valid Email address."
            }
            if (!this.apAcceptForm.phone || this.apAcceptForm.fullName.phone > 20) {
                return "Please enter valid Phone number."
            }
        },
        _validateAcceptForm() {
            if (!this.appNbbForm.sector) {
                return "Please select Sector."
            }
            if (this.appNbbForm.sector === 'Schools & Children' && !this.appNbbForm.subSector) {
                return "Please select School Type."
            }
            if (!this.appNbbForm.issue) {
                return "Please select Issue."
            }
            if (this.appNbbForm.institutionName && this.appNbbForm.institutionName.length > 150) {
                return "Institution name must be less then 150 char long."
            }
            if (this.appNbbForm.schoolName && this.appNbbForm.schoolName.length > 150) {
                return "School name must be less then 150 char long."
            }
            if (this.appNbbForm.country && this.appNbbForm.country.length > 100) {
                return "Country must be less then 100 char long."
            }
            if (this.appNbbForm.documentTypes && this.appNbbForm.documentTypes.length > 255) {
                return "Documents must be less then 255 char long."
            }
            if (!this.appNbbForm.meetingVia) {
                return "Please select type of Legal Advice."
            }
            if (!this.appNbbForm.heardAboutUs) {
                return "Please select how did you hear about us."
            }
            if (this.appNbbForm.documentTypes && this.appNbbForm.documentTypes.length > 1000) {
                return "Additional information must be less then 1000 char long."
            }
        },
    }
}
</script>

<style scoped>
*,
*:before,
*:after {
    box-sizing: border-box;
}
a {
  cursor: pointer;
}
.ap-form-window {
    max-width: 410px;
    /*height: calc(100% - 120px);*/
    max-height: 710px;
    position: fixed;
    right: 25px;
    bottom: 20px;
    box-sizing: border-box;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    animation: fadeIn;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
    border: 1px solid #dcdcdc;
}
.ap-form-window .ap-form-header {
    min-height: 75px;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    padding: 10px 30px 10px 20px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    position: relative;
    box-sizing: border-box;
    display: flex;
}
.ap-form-window .ap-form-container {
    position: relative;
    padding: 1em 1.2em 1.5em 1.2em;
    margin: 0 auto;
    height: 100%;
    overflow-y: auto;
}
.ap-form-window .ap-form-message-body {
    padding-top: 30px;
}
.ap-form-window.closed {
    opacity: 0;
    display: none;
    bottom: 90px;
}
@media (max-width: 450px) {
    .ap-form-window {
        width: 100%;
        height: 100%;
        max-height: 100%;
        right: 0;
        bottom: 0;
        border-radius: 0;
    }

    .ap-form-window {
        transition: 0.1s ease-in-out;
    }

    .ap-form-window.closed {
        bottom: 0;
    }
}
.ap-form-window .ap-centered {
    text-align: center;
}
.ap-form-window h3 {
    color: #264d87;
    font-weight: 400;
    font-size: 1.4rem;
    margin: 0;
    padding: .8rem 0 .7rem;
}
.ap-form-window .ap-header--img {
  border-radius: 50%;
  align-self: flex-start;
  width: 57px;
}
.ap-form-window .ap-header--title {
  align-self: center;
  padding: 7px;
  flex: 1;
  user-select: none;
  font-size: 20px;
}
.ap-form-window p {
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    padding: 5px 0;
    display: block;
    color: #000000;
}
.ap-form-window .ap-error {
    height: 40px;
    font-size: 14px;
    line-height: 16px;
    margin: 0;
    padding: 0;
    display: block;
    color: #F56C6C;
}
.ap-form-window .ap-btn {
    display: inline-block;
    white-space: nowrap;
    cursor: pointer;
    background: #FFF;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    color: #33475b;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0 10px 0 0;
    transition: .1s;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 0.7rem 1rem;
    text-decoration: none;
}
.ap-form-window .ap-btn.primary {
    background-color: #238d8f;
    border-bottom: 0.095rem solid rgba(0, 0, 0, 0.15);
    border-left: 0;
    border-radius: 4px;
    border-right: 0;
    border-top: 0;
    color: #fff;
}
.ap-form-window .ap-btn:hover {
  opacity:0.7;
}
.ap-form-window .ap-header--close-button {
    width: 37px;
    align-self: center;
    height: 37px;
    box-sizing: border-box;
    cursor: pointer;
    border: 1px solid rgba(0.2, 0.2, 0.5, 0.1);
    border-radius: 5px;
    position: absolute;
    top: 15px;
    right: 10px;
}
.ap-form-window .ap-header--close-button :hover {
    box-shadow: 0 2px 5px rgba(0.2, 0.2, 0.5, 0.1);
}
.ap-form-window .ap-header--close-button img {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
}
@keyframes fadeIn {
    0% {
        display: none;
        opacity: 0;
    }

    100% {
        display: flex;
        opacity: 1;
    }
}
.ap-form-window .ap-row {
    zoom: 1;
}
.ap-form-window .ap-row:before,
.ap-form-window .ap-row:after {
    content: "";
    display: table;
}
.ap-form-window .ap-row:after {
  clear: both;
}
.ap-form-window .ap-col-half {
    padding-right: 10px;
    float: left;
    width: 50%;
}
.ap-form-window .ap-col-half:last-of-type {padding-right: 0;}
.ap-form-window .ap-col-third {
    padding-right: 10px;
    float: left;
    width: 33.33333333%;
}
.ap-form-window .ap-col-third:last-of-type {
    padding-right: 0;
}
@media only screen and (max-width: 540px) {
    .ap-form-window .ap-col-half {
        width: 100%;
        padding-right: 0;
    }
}
.ap-form-window textarea,
.ap-form-window input[type="text"],
.ap-form-window input[type="number"],
.ap-form-window input[type="email"],
.ap-form-window select option,
.ap-form-window select {
    width: 100%;
    height: 34px;
    padding: 0.5rem;
    line-height: 18px;
    font-size: 13px;
    background-color: #f5f8fa;
    border: 1px solid #cbd6e2;
    border-radius: 3px;
    -webkit-transition: 0.35s ease-in-out;
    -moz-transition: 0.35s ease-in-out;
    -o-transition: 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
}
.ap-form-window textarea {
    height: auto;
    color: #33475b;
}
.ap-form-window textarea:focus {
  outline: 0;
  border-color: #33475b;
}
.ap-form-window input {
    color: #33475b;
}
.ap-form-window input:focus {
    outline: 0;
    border-color: #33475b;
}
.ap-form-window ::placeholder {
    color: #33475b;
    opacity: .7;
}
.ap-form-window .ap-label {
    color: #33475b;
    font-weight: 400;
    font-size: 14px;
    /*line-height: 1.9rem;*/
    margin: 0.5rem 0 0;
    /*margin: 0;*/
    padding: 0.3rem;
}
.ap-form-window input[type="checkbox"] {
    appearance: checkbox !important;
    margin-right: 5px;
}
.ap-form-window select {
    margin-bottom: 5px;
    color: #33475b;
}
.ap-input-group {
    padding: .4rem 0;
    line-height: 1.2rem;
    margin-bottom: .1em;
    zoom: 1;
}
.ap-input-group:before,
.ap-input-group:after {
    content: "";
    display: table;
}
.ap-input-group:after {
    clear: both;
}
</style>
